@mixin main-nav-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $is-dark: map-get($theme, is-dark);

  #expander {
    background: map-get($background, card);
  }
}