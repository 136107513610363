.mde-popover-panel {
  .mde-popover-content {
    padding-bottom: 0 !important;
  }

  .mde-popover-direction-arrow {
    border-color: transparent transparent #0b78d0;
  }

  .mat-mdc-card-title {
    color: white;
    font-size: 14px !important;
  }

  mat-card-content {
    padding: 16px;
  }

  .closeBtn {
    color: white;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
}
