// UDS Colors
// In which ever scss files you need them, import them.

$uds-grayscale-black: #000000;
$uds-grayscale-shadow: #6a6a6d;
$uds-grayscale-shadow-black: #3A3A42;
$uds-grayscale-fog: #bebec1;
$uds-grayscale-platinum: #e2e2e6;
$uds-grayscale-platinumlight: #D0D0D1;
$uds-grayscale-smoke: #f2f2f2;
$uds-grayscale-white: #ffffff;
$uds-grayscale-whitesmoke: #f5f5f5;
$uds-primary-ocean: #007ba8;
$uds-primary-blue: #0b78d0;
$uds-primary-sky: #e3f2fd;
$uds-primary-cloud: #f9fcff;
$uds-secondary-fescue: #5fb25c;
$uds-secondary-emerald: #00e676;
$uds-secondary-seafoam: #ecf9f3;
$uds-tertiary-tiger: #ffa000;
$uds-tertiary-gold: #ffc107;
$uds-tertiary-honeysuckle: #fff5e5;
$uds-alert-cherry: #ad2f33;
$uds-alert-scarlet: #e53935;
$uds-alert-rose: #eb7a6e;
$uds-interactive-ocean-hover: #076284;

$active: $uds-primary-ocean;
$completed: $uds-secondary-fescue;
$pending: $uds-tertiary-tiger;
$untrackable: $uds-alert-cherry;
$rejected: $uds-alert-scarlet;

@mixin Heading-Sub-Small {
  font-size: 15px;
  font-weight: 700;
}
@mixin Heading-Sub-Regular {
  font-size: 18px;
  font-weight: 700;
}
@mixin Heading-Sub-Large {
  font-size: 24px;
  font-weight: 700;
}

@mixin UDS-Default {
  font-family: 'Roboto' !important;
  font-size: 18px;
  font-weight: 400;
}
@mixin Body-Small {
  font-size: 12px;
  font-weight: 400;
}
@mixin Body-Regular {
  font-size: 15px;
  font-weight: 400;
  // color: $uds-grayscale-shadow;
}
@mixin Components-Table-Heading {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0em;
}
@mixin Components-Table-Label {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0em;
}
@mixin Popover-Heading {
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  color: #000000;
}
@mixin Mat-Tooltip($bg-color) {
  position: relative;
  background: $bg-color  !important;
  border: 1px solid $bg-color;
  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid $bg-color;
  }
}