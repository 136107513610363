@mixin playground-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $is-dark: map-get($theme, is-dark);

  .svgIcon {
    background-color: map-get($foreground, icon);
  }
  .svgIcon:hover,
  .svgIcon:focus {
    background-color: map-get($warn, text);
  }

}