// Import Dp core library
@import '@dp/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

@import '../node_modules/angular-calendar/css/angular-calendar.css';

@import 'scss/_grid';

@import 'scss/custom';

@font-face {
  font-family: Conthrax;
  src: url(assets/fonts/conthrax-sb.ttf);
}

@import 'scss/stylesUDS';
@import '../node_modules/@dpw/feedback-widget/assets/styles.css';

@media only screen and (max-width: 1400px) {
  body {
    zoom: 75%;

    iframe[title~='recaptcha'],
    iframe[title~='reCAPTCHA'] {
      zoom: 1.35;
    }

    [id^='ngx_captcha_id'] > div {
      width: unset !important;
      height: unset !important;
    }

    .cdk-overlay-container .mat-mdc-select-panel-above:has([aria-labelledby^='mat-paginator-page-size-label']) {
      bottom: 0 !important;
    }

    .position-handler + .cdk-overlay-connected-position-bounding-box {
      right: inherit !important;
    }

    .cdk-overlay-pane {
      &:has(dp-invite-user-popover) {
        right: 145px !important;
      }

      &:has(.notification-popover-card) {
        top: 48px !important;
        right: 105px !important;
      }
      &:has(#help-popover-card) {
        right: 62px !important;
        top: 66px !important;
        bottom: unset !important;

        .mde-popover-direction-arrow {
          top: -16px;
          transform: rotate(180deg);
        }
      }
      &:has(#profile-popover-card) {
        right: 10px !important;
        top: 66px !important;
        bottom: unset !important;

        .mde-popover-direction-arrow {
          top: -16px;
          transform: rotate(180deg);
        }
      }
      &:has(.mdc-card.Legend) {
        right: 70px !important;
      }
      &:has(.new-card-view-popover) {
        right: 45% !important;
        position: absolute !important;
      }
    }
    .introjs-helperLayer,
    .introjs-tooltipReferenceLayer {
      zoom: 1.333;
    }
  }
}

.bingMapsCustomInfobox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -18px);
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 300;

  .body {
    display: flex;
    margin-right: 20px;
  }

  img {
    height: 22px;
    padding-left: 4px;
    padding-right: 6px;
    margin-right: 6px;
    border-right: 1.5px solid darkgray;
  }
  .infobox-close-img {
    position: absolute;
    top: 0px;
    right: 7px;
    cursor: pointer;
  }
  .arrow {
    filter: drop-shadow(0 4px 2px rgba(178, 178, 178, 0.4));
    position: absolute;
    bottom: 0;
    transform: translateX(-14px);

    &::after {
      background: #fff;
      -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      content: '';
      height: 12px;
      left: 0;
      position: absolute;
      top: -1px;
      width: 25px;
    }
  }
}

.customBoardInfobox {
  top: -25px;
  position: relative;
  left: -25px;
  cursor: pointer;
  .title {
    position: absolute;
    top: 3px;
    left: 2px;
    width: 20px;
    text-align: center;
  }
}

.bingMap {
  #MicrosoftNav.NavBar_Container {
    inset: auto 0px 0px auto !important;
    right: 10px !important;
  }
}
#bingMapDashboard {
  #MicrosoftNav.NavBar_Container {
    inset: 5px auto auto 5px !important;
  }
}

.bm_bottomRightOverlay .CopyrightControl a {
  pointer-events: none !important;
}

.cdk-overlay-container:has(.new-card-view-popover) {
  .cdk-overlay-backdrop {
    background-color: #000;
    opacity: 0.2;
  }
}

.pagination {
  padding-right: 30px;
}
