@mixin welcome-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $is-dark: map-get($theme, is-dark);

  .example-card:hover {
   //  background-color: green !important;
     background-color: mat-color($accent, 100);
  }



  // .selected {
  //   background-color: mat-color($accent, 300);
  // }

  // strong {
  //   color: mat-color($warn, 600);
  // }

  // dp-po {
  //   .header {

  //     .search-wrapper {
  //       background: map-get($background, card);

  //       .search {

  //         .mat-icon {
  //           color: map-get($foreground, icon);
  //         }

  //         input {
  //           background: map-get($background, card);
  //           color: map-get($foreground, text);
  //         }
  //       }
  //     }
  //   }
  // }
}