@import './variables.scss';

//UDS Styles For Components

//Select Place Holder
//todo: conflict for dropdown in the shipment page
// .mat-select-placeholder {
//   color: #757575;
//   letter-spacing: 0.4px;
//   font-family: Montserrat;
// }

//text resize none
textarea.mat-mdc-input-element {
  resize: none !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
}

//Check Box Styles

.easy-check-box.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
  background: $uds-primary-blue;
  border: 1px solid $uds-primary-blue;
  box-sizing: border-box;
  border-radius: 2px;
}

.easy-check-box.mat-accent .mat-mdc-checkbox-background {
  background: $uds-grayscale-white;
  border: 1px solid $uds-grayscale-shadow;
  box-sizing: border-box;
  border-radius: 2px;
}

.easy-check-box.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element {
  background: $uds-primary-blue;
}
.easy-check-box.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element {
  background: $uds-primary-blue;
}
.easy-check-box.mat-mdc-checkbox-disabled.mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
  background: $uds-grayscale-shadow;
  border: 1px solid $uds-grayscale-shadow;
  box-sizing: border-box;
  border-radius: 2px;
}

.easy-check-box.mat-mdc-checkbox-disabled .mat-mdc-checkbox-background {
  background: $uds-grayscale-platinum;
  border: 1px solid $uds-grayscale-shadow;
  box-sizing: border-box;
  border-radius: 2px;
}

.easy-check-box .mat-mdc-checkbox-inner-container {
  width: 19px;
  height: 19px;
}

//Button Styling
.easy-button {
  font-weight: 400;
  &.mat-mdc-mini-fab .mdc-button__label {
    font-size: 14px;
    color: #fff;
  }
}

.easy-button.mat-mdc-unelevated-button {
  background: $uds-primary-blue !important;
  box-sizing: border-box;
  border-radius: 3px;
  border: 3px solid $uds-primary-blue;
  box-sizing: border-box;
  border-radius: 3px;
  // height: 39px;
  // line-height: 33px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 9px 18px;
}
.easy-button.mat-mdc-mini-fab {
  background: $uds-primary-blue !important;
  &:hover {
    background: #0a6fc2 !important;
  }
}

.easy-button.secondary.mat-mdc-unelevated-button {
  background: $uds-grayscale-white !important;
  // border: 3px solid $uds-primary-blue;
  // box-sizing: border-box;
  // border-radius: 3px;
}

.easy-button.tertiary.mat-mdc-unelevated-button {
  background: none !important;
  border: none;
}

.easy-button.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled='true'] {
  background: $uds-grayscale-fog !important;
  border-color: transparent;
}

.easy-button.mat-mdc-unelevated-button:not(.secondary):not(.tertiary):not(.mat-mdc-unelevated-button[disabled]):hover {
  background: #0a6fc2 !important;
}

.easy-button.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled]:hover {
  background: $uds-grayscale-fog;
}

.easy-button.secondary.mat-flat-button:not(.mat-mdc-checkbox-disabled):hover {
  background: $uds-grayscale-smoke;
}

.easy-button.secondary.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled],
.easy-button.secondary.mat-mdc-unelevated-button.mat-button-disabled {
  background: white !important;
  border: 3px solid $uds-grayscale-fog;
  box-sizing: border-box;
  border-radius: 3px;
}

.easy-button.secondary.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled]:hover {
  background: $uds-grayscale-smoke;
}

.easy-button.secondary.mat-flat-button:not(.mat-mdc-checkbox-disabled):hover {
  background: $uds-grayscale-smoke !important;
}

.easy-button.tertiary.mat-flat-button:not(.mat-mdc-checkbox-disabled):hover {
  background: $uds-grayscale-smoke;
}

.easy-button.tertiary.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled] {
  background: $uds-grayscale-white;
  border: none;
}

.easy-button.tertiary.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled]:hover {
  background: $uds-grayscale-white;
}

.easy-button.mat-mdc-unelevated-button .mdc-button__label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: $uds-grayscale-white;
}

.easy-button.secondary.mat-mdc-unelevated-button .mdc-button__label {
  color: $uds-primary-blue;
}

.easy-button.secondary.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled] .mdc-button__label,
.easy-button.secondary.mat-mdc-unelevated-button.mat-button-disabled .mdc-button__label {
  color: $uds-grayscale-fog;
}

.easy-button.tertiary.mat-mdc-unelevated-button .mdc-button__label {
  color: $uds-primary-blue;
}

.easy-button.tertiary.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled] .mdc-button__label {
  color: $uds-grayscale-fog;
}

.easy-button.mat-mdc-unelevated-button.mat-mdc-unelevated-button[disabled] .mdc-button__label {
  color: $uds-grayscale-smoke;
}

.easy-button mat-icon {
  margin-right: 0 4px 0 -6px;
  font-size: 20px !important;
  line-height: 24px;
}

//Radio Button Styling

.easy-radio-button .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-color: $uds-primary-blue;
}

.easy-radio-button .mat-mdc-radio-button:not(.mat-mdc-checkbox-disabled).mat-accent.mat-mdc-radio-checked .mat-ripple-element {
  background: $uds-primary-blue;
}

//Not able to find the exact reason for not working will remove important in future
.easy-radio-button .mat-mdc-radio-button.mat-accent:active .mat-ripple-element {
  background-color: $uds-primary-blue !important;
}

.easy-radio-button .mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.easy-radio-button .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-radio-persistent-ripple,
.mat-mdc-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: $uds-primary-blue;
}

.easy-radio-button .mat-mdc-radio-button.mat-radio-disabled.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle,
.easy-radio-button .mat-mdc-radio-button.mat-radio-disabled.mat-accent .mdc-radio__outer-circle {
  border-color: $uds-grayscale-shadow;
  background-color: $uds-grayscale-platinum;
}

.easy-radio-button .mat-mdc-radio-button.mat-radio-disabled.mat-accent .mdc-radio__inner-circle,
.easy-radio-button
  .mat-mdc-radio-button.mat-radio-disabled.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.easy-radio-button .mat-mdc-radio-button.mat-radio-disabled.mat-accent.mat-mdc-radio-checked .mat-radio-persistent-ripple,
.easy-radio-button .mat-mdc-radio-button.mat-radio-disabled.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $uds-grayscale-shadow;
}
.mat-mdc-radio-button,
.mat-mdc-checkbox {
  .mdc-form-field > label {
    @include UDS-Default;
    font-family: 'Montserrat', sans-serif !important;
    line-height: 21px;
    color: #000000de;
  }
}

//Toggle Styling

.easy-side-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $uds-primary-blue;
}

.easy-side-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $uds-grayscale-white;
}

.easy-side-toggle .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
}

.easy-side-toggle .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.easy-side-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-thumb-container {
  transform: translate3d(25px, 0, 0);
}

.easy-side-toggle .mat-slide-toggle-label .mat-slide-toggle-bar {
  position: relative;
  width: 45px;
  height: 19px;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: $uds-grayscale-shadow;
}

.easy-side-toggle.mat-slide-toggle .mat-ripple.mat-slide-toggle-ripple .mat-ripple-element {
  background-color: $uds-primary-blue;
}

.easy-side-toggle.mat-slide-toggle:not(.mat-checked) .mat-ripple.mat-slide-toggle-ripple .mat-ripple-element {
  background-color: $uds-grayscale-smoke;
}

.easy-side-toggle .mat-slide-toggle-content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: right;
  color: #000000;
}

//Chips Styling
.easy-chip.mat-chip.mat-standard-chip {
  font-family: Montserrat;
  font-size: 15px;
  height: 32px;
  padding: 7px 7px;
}
//Tabs Styling

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 6px !important;
  background-color: $uds-primary-blue !important;
}

.mdc-tab {
  &.mat-mdc-tab-link {
    min-width: 160px !important;
  }
  .mdc-tab__text-label {
    font-size: 12px;
    font-weight: 700;
  }
  &:not(.mat-mdc-tab-disabled):not(.mdc-tab--active) .mdc-tab__text-label {
    color: #6a6a6d !important;
    opacity: 0.6;
  }
}

.mat-mdc-tab-link-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.easy-tab .mdc-tab--active.mdc-tab__content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0b78d0;
}

.easy-tab .mdc-tab__text-label {
  opacity: 1;
}

.easy-tab .mdc-tab__text-label:hover {
  border-bottom: 6px solid #6a6a6d;
  background-color: #f2f2f2;
}

.easy-tab .mdc-tab__content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #6a6a6d;
}

.easy-tab .mdc-tab__text-label.mdc-tab--active:hover {
  background-color: #f2f2f2;
}

body.theme-default {
  $outline-color: $uds-grayscale-shadow;
  $outline-color-hover: $uds-primary-blue;
  $outline-color-primary: $uds-primary-blue;
  $outline-color-accent: $uds-primary-blue;
  $outline-color-warn: $uds-alert-cherry;
  $outline-color-disabled: green;
  $label-disabled-color: $uds-grayscale-fog;

  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  .mat-mdc-select-panel,
  .mdc-list-item__primary-text,
  .mdc-text-field__input,
  .mat-mdc-select,
  .mat-mdc-form-field-subscript-wrapper,
  .mdc-button,
  .mat-mdc-card-title,
  .mdc-data-table__cell,
  .mdc-form-field,
  .mdc-data-table__header-cell,
  .mat-mdc-form-field,
  .mdc-tab {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .mat-mdc-card {
    padding: 16px;
    &.Legend {
      background: #fff;
    }
  }
  .mat-mdc-card-header {
    min-height: 32px;
  }
  .mat-form-field-appearance-outline {
    &:not(.notes-field, .cdkTextareaAutosizeCls) .mdc-text-field--outlined {
      height: 36px;
      align-items: center;
    }
    .mat-mdc-text-field-wrapper {
      margin-top: 0.25em;
      padding: 0 0.75em 0 0.75em;
    }
    .mat-mdc-form-field-subscript-wrapper {
      margin-bottom: 0.25em;
    }
    .mat-mdc-form-field-icon-suffix {
      padding-left: 0;
      .mat-mdc-button > .mat-icon {
        margin-right: 0;
      }
    }
    .mdc-notched-outline__notch {
      max-width: calc(100% - 18px) !important;
    }
    &:not(.cdkTextareaAutosizeCls) .mdc-notched-outline {
      color: $outline-color;
      .mdc-notched-outline__leading {
        width: 5.5px;
      }

      .mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 50% !important;
      }
      .mdc-floating-label.mdc-floating-label--float-above {
        font-size: 15px;
        max-width: initial;
      }
    }

    .mdc-notched-outline__leading {
      border-radius: 5px 0 0 5px;
    }

    .mdc-notched-outline__trailing {
      border-radius: 0 5px 5px 0;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      color: $outline-color;
      border-color: currentColor !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid):hover,
    &.mat-focused .mdc-notched-outline--notched {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        color: $outline-color-hover;
        border-width: 2px;
        border-color: currentColor !important;
      }
    }

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: $outline-color-primary;
      }

      &.mat-accent .mat-form-field-outline-thick {
        color: $outline-color-accent;
      }

      &.mat-warn .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }

    // Class repeated so that rule is specific enough to override focused accent color case.
    &.mat-form-field-invalid.mat-form-field-invalid {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        color: $outline-color-warn;
        border-width: 2px;
        border-color: currentColor !important;
      }

      .mat-mdc-select-arrow {
        color: #ad2f33;
      }
    }

    &.mat-form-field-disabled {
      .mat-mdc-floating-label {
        color: $label-disabled-color;
      }

      .mdc-notched-outline,
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        color: $outline-color-disabled;
      }
    }
  }

  .mat-form-field-appearance-outline.mat-mdc-paginator-page-size-select {
    .mdc-text-field--outlined {
      height: 28px;
      .mat-mdc-select {
        margin-top: 0.1em;
      }
      .mdc-notched-outline__leading {
        border-radius: 3px 0 0 3px;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0 3px 3px 0;
      }
    }
  }

  $label-color: #000000;
  $focused-label-color: #000000;
  $underline-color-accent: #000000;
  $underline-color-warn: $uds-alert-cherry;
  $required-label-color: #000000;
  $underline-color-base: #000000;
  $underline-focused-color: #000000;

  // .mat-form-field-label {
  //   color: $label-color;
  // }

  .mat-hint {
    color: $label-color;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-floating-label {
    color: $focused-label-color;

    &.mat-accent {
      color: $underline-color-accent;
    }

    &.mat-warn {
      color: $underline-color-warn;
    }
  }

  .mat-focused .mat-mdc-form-field-required-marker {
    color: $required-label-color;
  }

  .mat-form-field-ripple {
    background-color: $underline-color-base;
  }

  .mat-mdc-form-field.mat-focused {
    .mat-form-field-ripple {
      background-color: $underline-focused-color;

      &.mat-accent {
        background-color: $underline-color-accent;
      }

      &.mat-warn {
        background-color: $underline-color-warn;
      }
    }
  }

  .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) {
    .mat-form-field-infix::after,
    .mat-mdc-form-field-infix::after {
      color: $underline-focused-color;
    }

    &.mat-accent .mat-form-field-infix::after,
    &.mat-accent .mat-mdc-form-field-infix::after {
      color: $underline-color-accent;
    }

    &.mat-warn .mat-form-field-infix::after,
    &.mat-warn .mat-mdc-form-field-infix::after {
      color: $underline-color-warn;
    }
  }

  // Styling for the error state of the form field. Note that while the same can be
  // achieved with the ng-* classes, we use this approach in order to ensure that the same
  // logic is used to style the error state and to show the error messages.
  .mat-mdc-form-field.mat-form-field-invalid {
    .mat-mdc-floating-label {
      color: $underline-color-warn;

      &.mat-accent,
      .mat-mdc-form-field-required-marker {
        color: $underline-color-warn;
      }
    }

    .mat-form-field-ripple,
    .mat-form-field-ripple.mat-accent {
      background-color: $underline-color-warn;
    }
  }

  .mat-error,
  .mat-mdc-form-field-error {
    color: $underline-color-warn;
  }

  .mat-mdc-menu-item {
    .mdc-list-item__primary-text {
      font-size: 14px;
      align-items: center;
      display: flex;
    }
  }

  .mat-mdc-menu-item .mdc-list-item__primary-text,
  .mat-mdc-form-field .mat-mdc-floating-label {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .mdc-floating-label--required::after {
    margin-left: 4px;
  }
}

// Form Field elements styling
// .mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
//   background: #ffffff;
//   color: #6a6a6d;
// }

// .mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start,
// .mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
//   border-color: #6a6a6d;
// }

// .mat-focused.mat-form-field-appearance-outline
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-start {
//   border-color: #0b78d0;
//   border-right-style: none;
// }

// .mat-focused.mat-form-field-appearance-outline
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-end {
//   border-color: #0b78d0;
//   border-left-style: none;
// }

// .mat-form-field-appearance-outline.mat-form-field-disabled
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-start,
// .mat-form-field-appearance-outline.mat-form-field-disabled
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-end {
//   border-color: #bebec1;
// }

// .mat-focused.mat-form-field-appearance-outline
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-gap {
//   border: 1px solid #0b78d0;
//   border-left-style: none;
//   border-right-style: none;
//   border-top-style: none;
// }

// .mat-form-field-invalid.mat-form-field-appearance-outline
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-start {
//   border-color: #ad2f33;
//   border-right-style: none;
// }

// .mat-form-field-invalid.mat-form-field-appearance-outline
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-end {
//   border-color: #ad2f33;
//   border-left-style: none;
// }

// .mat-form-field-invalid.mat-form-field-appearance-outline
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-gap {
//   border-top-color: transparent;
//   border-left-style: none;
//   border-right-style: none;
// }
// .mat-form-field-invalid.mat-focused.mat-form-field-appearance-outline
//   .mat-form-field-wrapper
//   .mat-form-field-flex
//   .mat-form-field-outline
//   .mat-form-field-outline-gap {
//   border-top-style: none;
// }
// .mat-form-field-appearance-outline .mat-form-field-outline:hover {
//   color: #0b78d0;
// }

// .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
//   background: #f9fcff;
//   color: #0b78d0;
// }

// .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
//   background-color: #fff5e5;
//   color: #ad2f33;
// }

// .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
//   background: #f2f2f2;
//   color: #bebec1;
// }

.mat-form-field-subscript-wrapper .mat-error,
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error {
  font-size: 12px;
}

//This should be removed one easy login recent is merged
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid {
  margin-bottom: 0px !important;
}

//todo: conflict
// .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
//   height: 39px;
//   padding: 0px;
// }

//Input Styles
.easy-form {
  //.mat-input-element,
  // .mat-option-text,
  // .mat-form-field .mat-form-field-label,
  // .mat-card,
  // .mat-select-placeholder
  .mat-mdc-select-value,
  .mat-mdc-select-value-text {
    font-size: 15px !important;
  }
  &.no-hint .mat-mdc-form-field-bottom-align {
    display: none;
  }
}

mat-form-field.no-hint .mat-mdc-form-field-bottom-align {
  display: none;
}

.easy-list {
  .mat-mdc-select-value,
  .mat-mdc-select-value-text {
    font-size: 15px !important;
  }
}

// .mat-form-field-label-wrapper {
//   top: -22px !important;
// }
// .mat-focused .mat-form-field-label-wrapper {
//   top: -15px !important;
// }
// .mat-form-field-should-float .mat-form-field-label-wrapper {
//   top: -15px !important;
// }

// .mat-form-field-appearance-outline .mat-form-field-flex {
//   height: 42px;
// }

// .mat-form-field-hint-wrapper .mat-hint {
//   font-family: Montserrat;
//   font-size: 12px;
//   color: #000000;
// }

//todo: conflict
// .mat-select {
//   margin-top: 4px;
// }
// .mat-select-arrow-wrapper .mat-select-arrow {
//   margin: 13px 4px;
// }

.mat-select-arrow-wrapper {
  height: auto !important;
}

//Normal input elements styling
input.mat-mdc-input-element.separate-input {
  background: #ffffff;
  border: 1px solid #6a6a6d;
  box-sizing: border-box;
  border-radius: 3px;
  height: 30px;
  padding: 9px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
}

input.mat-mdc-input-element.separate-input:focus {
  background: #f9fcff;
  border: 1px solid #0b78d0;
}

input.mat-mdc-input-element.separate-input:disabled {
  background: #f2f2f2;
  border: 1px solid #bebec1;
  box-sizing: border-box;
  border-radius: 3px;
  color: #bebec1;
}

textarea.mat-mdc-input-element.separate-textarea {
  background: #ffffff;
  border: 1px solid #6a6a6d;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 9px;
  resize: none;
}

textarea.mat-mdc-input-element.separate-textarea:focus {
  background: #f9fcff;
  border: 1px solid #0b78d0;
}

textarea.mat-mdc-input-element.separate-textarea:disabled {
  background: #f2f2f2;
  border: 1px solid #bebec1;
  color: #bebec1;
}

//Cards
.mat-card:not([class*='mat-elevation-z']).easy-card {
  box-shadow: none;
}

//Tables

table {
  width: 100%;
}

.easy-table.mat-table tr:nth-child(even),
.easy-table.mat-mdc-table tr:nth-child(even) {
  background: #f2f2f2;
}

.easy-table.mat-table td,
.easy-table.mat-mdc-table td {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.easy-table.mat-table th,
.easy-table.mat-mdc-table th {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #007ba8;
}

.easy-table tr.mat-mdc-header-row {
  height: 39px;
}

.easy-table thead th.mat-mdc-header-cell {
  border-bottom: 3px solid #007ba8;
}

.easy-paginator .mat-mdc-paginator-page-size-label,
.easy-paginator .mat-mdc-paginator-range-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  color: #000000;
}

// This is for time being will work on paginator soon.
.easy-paginator .mat-select-arrow {
  margin: 0 4px !important;
}
// mat tooltip
.mdc-tooltip {
  .mdc-tooltip__surface {
    @include Mat-Tooltip($uds-primary-ocean);
    white-space: pre-line;
    line-height: 21px;
    max-width: 250px;
  }
  &.below .mdc-tooltip__surface {
    overflow: initial;

    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above .mdc-tooltip__surface {
    overflow: initial;

    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right .mdc-tooltip__surface {
    overflow: initial;

    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left .mdc-tooltip__surface {
    overflow: initial;

    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}
.mat-tooltip {
  @include Mat-Tooltip($uds-primary-ocean);
  white-space: pre-line;
  &.below {
    overflow: initial;
    margin-top: 1rem;

    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;
    margin-bottom: 1rem;

    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;

    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;

    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}
.mdc-tooltip__surface {
  padding: 10px !important;
  font-size: 14px !important;
}

.flow-predicts-custom-tooltip {
  max-width: 272px !important;
}
//Tooltip Styling
.ng-tooltip {
  position: absolute;
  max-width: 200px;
  text-align: center;
  background: #007ba8;
  z-index: 1000;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  line-height: 21px;
}

.ng-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 9px;
  border-color: #007ba8 transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 9px;
  border-color: transparent transparent #007ba8 transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -10px;
  border-width: 9px;
  border-color: transparent transparent transparent #007ba8;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -10px;
  border-width: 9px;
  border-color: transparent #007ba8 transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.ng-tooltip-success {
  background: #5fb25c;
  color: #000000;
}

.ng-tooltip-success.ng-tooltip-top:after {
  border-color: #5fb25c transparent transparent transparent;
}

.ng-tooltip-success.ng-tooltip-bottom:after {
  border-color: transparent transparent #5fb25c transparent;
}

.ng-tooltip-success.ng-tooltip-left:after {
  border-color: transparent transparent transparent #5fb25c;
}

.ng-tooltip-success.ng-tooltip-right:after {
  border-color: transparent #5fb25c transparent transparent;
}

.ng-tooltip-yellow {
  background: #ffc107;
  color: #000000;
}

.ng-tooltip-yellow.ng-tooltip-top:after {
  border-color: #ffc107 transparent transparent transparent;
}

.ng-tooltip-yellow.ng-tooltip-bottom:after {
  border-color: transparent transparent #ffc107 transparent;
}

.ng-tooltip-yellow.ng-tooltip-left:after {
  border-color: transparent transparent transparent #ffc107;
}

.ng-tooltip-yellow.ng-tooltip-right:after {
  border-color: transparent #ffc107 transparent transparent;
}

// Header Text Styling

.header-primary-lg {
  font-family: Conthrax !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 72px !important;
  line-height: 72px !important;
  text-transform: uppercase !important;
  color: #000000;
}

.header-primary-rg {
  font-family: Conthrax !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 48px !important;
  text-transform: uppercase !important;
  color: #000000;
}

.header-primary-sm {
  font-family: Conthrax !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 30px !important;
  text-transform: uppercase !important;
  color: #000000;
}

.header-primary-xs {
  font-family: Conthrax !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  text-transform: uppercase !important;
  color: #000000;
}

// Header Sub Text Styling

.header-sub-xlg {
  font-family: Montserrat, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 36px !important;
  line-height: 33px !important;
  color: $uds-grayscale-black;
}

.header-sub-lg {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #000000;
}

.header-sub-rg {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #000000;
}

.header-sub-sm {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 21px !important;
  color: #000000;
}

//Body Text Styling

.text-lg {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 21px !important;
  line-height: 30px !important;
  color: #000000;
}

.text-rg {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 21px !important;
  color: #000000;
}

.text-sm {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #000000;
}
.text-label {
  font-family: Montserrat;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #000000;
}

.text-label-lg {
  font-family: Montserrat, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  text-transform: capitalize;
  color: $uds-alert-cherry;
}

.text-xs {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 12px !important;
  color: #000000;
}

//Date Picker Styles
.datepicker {
  .mdc-button__label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #000000;
  }
  .mat-calendar-body-cell-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }
  .mat-calendar-body-selected {
    background: #007ba8;
    color: #ffffff;
    box-shadow: inset 0 0 0 0px white;
  }
  .mat-calendar-body-today {
    box-shadow: inset 0 0 0 0px white !important;
  }
}

// Overlay For Loader
#overlay {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999999;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

//Material UI Loader CSS
.easy-loader.mat-progress-spinner circle {
  stroke: #076284;
}

button.tiny.mat-mdc-unelevated-button[disabled] {
  border: 2px solid transparent !important;
}
button.tiny:not(.mat-mdc-unelevated-button[disabled]) {
  border: 2px solid $uds-primary-blue !important;
}
button.tiny {
  line-height: 16px;
  min-height: 16px;
  vertical-align: top;
  font-size: 8px;
  letter-spacing: 0.4px;
  padding: 0 8px;
  min-width: inherit;
}

button.small {
  line-height: 20px;
  min-height: 20px;
  vertical-align: top;
  font-size: 10px;
  letter-spacing: 0.4px;
}

button.medium {
  line-height: 28px;
  font-size: 12px;
  vertical-align: top;
  letter-spacing: 0.4px;
}
button.button {
  line-height: 30px;
  font-size: 12px;
  vertical-align: top;
  letter-spacing: 0.4px;
  margin-top: -1px; // make the control 44px and set the button to the middle vertically
}

.easy-button.mat-mdc-unelevated-button.tiny .mdc-button__label {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  // color: $uds-grayscale-white;
}

.easy-sub-title {
  color: #6a6a6d;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: unset;
  margin-bottom: 15px;
}
.mat-slide-toggle-bar {
  height: 19px !important;
  width: 45px !important;
  border-radius: 22.5px !important;
}
.mat-slide-toggle-thumb {
  height: 15px !important;
  width: 15px !important;
}
body.theme-default .mat-slide-toggle-bar {
  background-color: #6a6a6d !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #0b78d0 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(26px, 0, 0) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white !important;
}
.mat-slide-toggle-thumb-container {
  height: 19px !important;
  width: 45px !important;
  top: 2px !important;
  left: 2px !important;
}
.mat-slide-toggle-ripple.mat-ripple {
  display: none;
}

.uds-svg {
  path {
    fill: #0b78d0;
  }
  &:hover path {
    filter: brightness(150%);
  }
}
.uds-svg.disabled {
  path {
    fill: #333333;
  }
  &:hover path {
    // fill: #333333;
    // filter: none;
  }
}
new-ticket-dialog form,
.easy-form {
  .mdc-floating-label {
    font-size: 15px;
    font-family: 'Montserrat';
    color: #0009;
  }
  .mat-form-field-infix {
    padding: 0px !important;
  }
  .description-input .mat-mdc-form-field-infix {
    border-top: 11.5px solid transparent;
    padding: 0 0 8px !important;
  }
  .mat-form-field-label-wrapper {
    top: -1.15em;
    padding-top: 1.15em;
  }
  .mat-mdc-input-element .mat-mdc-select-value-text {
    font-size: 15px !important;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.09375em) scale(0.75);
  }

  .easy-form-block {
    // width: 1000px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  // calendar icon start
  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    width: 2.1em;
    height: 2.1em;
  }
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.4em;
  }
  // calendar icon end

  .easy-form-block-title {
    font-size: 15px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin-bottom: 12px;
  }
  .easy-form-block-detail {
    border: 1px solid #bebec1;
    padding: 43px 43px 21px 43px;
  }
  mat-divider {
    margin: 20px 0;
  }
}

.easy-tab-group {
  .mat-mdc-tab-body-wrapper {
    margin-top: 21px;
  }
}

.easy-list {
  .easy-list-icon {
    min-width: 11px;
    width: 11px;
    min-height: 11px;
    height: 11px;
    font-size: 11px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .easy-list-line {
    font-size: 9px;
    font-weight: 400;
    font-family: 'Montserrat';
  }
  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    padding: 0px !important;
  }
}

.mat-dialog-title,
.mdc-dialog__title {
  // @include Heading-Sub-Large;
  font-size: 24px !important;
  font-weight: 700 !important;
  // font-family: 'Montserrat';
  margin: 0 0 20px !important;
}

.mdc-dialog__title:not(.col-12) {
  padding: 0px !important;
  &::before {
    display: inline !important;
  }
}
.mdc-dialog__title {
  &::before {
    display: inline !important;
  }
}
.mdc-dialog__title.col-12 {
  padding: 0 15px !important;
}
.close-icon {
  font-size: 34px;
  margin: 0;
  width: 34px !important;
  height: 34px !important;
  min-width: 34px;
  min-height: 34px;
  line-height: 34px;
}

body.theme-default .mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
}
body.theme-default .mat-expansion-indicator::after {
  color: $uds-primary-blue;
}

body.theme-default a {
  color: $uds-primary-blue;
  cursor: pointer;
}
body:not(.is-mobile) ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 12px rgb(0 0 0 / 20%);
}
//topography list start
.uds-default {
  @include UDS-Default;
}
.heading-sub-small {
  @include Heading-Sub-Small;
}
.heading-sub-regular {
  @include Heading-Sub-Regular;
}
.body-regular {
  @include Body-Regular;
}
.components-table-heading {
  @include Components-Table-Heading;
}

.box-title {
  @include Components-Table-Heading;
  color: $uds-primary-ocean;
}
//topography list end

.uds-table {
  background-color: white;
  // border-collapse: collapse;
  // white-space: nowrap;
  box-sizing: border-box;

  &.mat-mdc-table .mat-mdc-row:hover {
    background: inherit !important;
  }
  .mat-mdc-header-cell {
    font-size: 12px !important;
  }

  tr.mat-mdc-header-row {
    height: 45px;
  }

  tr.mat-mdc-row,
  tr.mat-footer-row {
    height: 65px;
  }

  td,
  th {
    padding-left: 7px;
    padding-right: 7px;
  }

  .mat-mdc-row {
    // border-bottom: .1px solid rgba(192, 191, 191, 50);
  }

  &.mat-mdc-table .mat-mdc-row:nth-child(2n) {
    background-color: #f2f2f2 !important;
  }

  // .mat-mdc-row:nth-child(2n + 1) {
  //   border-bottom: 0.2px solid whitesmoke;
  // }

  .mat-mdc-row.highlighted {
    background-color: #ccc !important;
  }

  td.mat-mdc-cell {
    border-bottom-width: 0 !important;
    color: black;
    padding-left: 15px;
    font-weight: normal;
  }

  &.alternate-rows {
    .mat-mdc-row {
      border-bottom: none;
    }

    .mat-mdc-row:nth-child(2n) {
      background-color: white !important;
    }

    // due to it has detail row
    .mat-mdc-row:nth-child(4n + 1) {
      // background-color: whitesmoke;
      // border-bottom: .1px solid rgba(192, 191, 191, 50);
    }

    .mat-mdc-row:nth-child(4n - 1) {
      background-color: whitesmoke !important;
    }

    .mat-mdc-row.highlighted {
      background-color: #d6ebfd !important;
    }

    // border second row
    thead tr:nth-child(2),
    .uds-main-header {
      th {
        color: $uds-primary-ocean;
        border-bottom: 3px solid $uds-primary-ocean;
        font-weight: 700;
        padding-left: 15px;
      }
    }
  }

  &.colored-header {
    thead tr {
      th {
        color: $uds-primary-ocean;
        border-bottom: 3px solid $uds-primary-ocean;
        font-weight: 700;
        padding-left: 15px;
      }
    }
  }
}

.uds-paginator {
  background-color: $uds-grayscale-white;
  .mat-mdc-paginator-container {
    margin-left: 20px;
  }
  .uds-margin-right .mat-mdc-paginator-container {
    margin-right: 20px;
  }
  .uds-upload-history .mat-mdc-paginator-container {
    background-color: $uds-grayscale-whitesmoke;
    margin-left: 0px;
    margin-right: 0px;
  }
  .mat-mdc-icon-button {
    width: 24px !important;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    box-sizing: border-box;
    border: 1px solid $uds-grayscale-shadow;
    border-radius: 3px;
  }

  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }
  .mat-mdc-paginator-page-size {
    align-items: center;
  }

  .mat-mdc-paginator-page-size-select {
    margin-top: 0px;
    width: 56px;
    .mat-mdc-text-field-wrapper {
      padding: 0px 5px !important;
      mat-select {
        padding-bottom: 3px;
      }
    }
  }

  .mat-mdc-select {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .mat-mdc-select-value {
    @include Body-Small;
    color: $uds-grayscale-black;
  }
  .mat-mdc-paginator-page-size-label {
    @include Components-Table-Heading;
    color: $uds-grayscale-black;
    text-transform: uppercase;
  }

  .mat-mdc-paginator-navigation-first {
    order: 1;
  }

  .mat-mdc-paginator-navigation-previous {
    order: 2;
  }

  .mat-mdc-paginator-range-label {
    order: 3;
    @include Components-Table-Heading;
    text-transform: uppercase;
    color: $uds-grayscale-black;
    margin: 0 10px 0 0;
  }

  .uds-upload-history .mat-mdc-paginator-range-label {
    max-width: 86px;
    text-align: center;
  }

  .mat-mdc-paginator-navigation-next {
    order: 4;
  }

  .mat-mdc-paginator-navigation-last {
    order: 5;
  }
  .mat-mdc-paginator-icon {
    margin-left: -12px;
  }
}

.uds-hint {
  @include Body-Small;
  color: $uds-secondary-fescue !important;
}

.feedback-container {
  .feed-back-grid-container {
    max-width: none !important;
  }
  .file-list-container {
    .file-description {
      .file-description-info {
        h4 {
          margin-top: 0px !important;
        }
      }
    }
  }
  .easy-button.mat-mdc-unelevated-button {
    height: 39px !important;
  }
  .mat-form-field-appearance-outline .mdc-text-field--outlined:has(textarea) {
    height: inherit !important;
  }
  .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-bottom: 8px !important;
  }

  .mat-mdc-table:not(.uds-table) thead .mat-mdc-header-cell {
    color: inherit !important;
  }
  .title-item.table-dropdown {
    .mdc-text-field--outlined {
      height: 39px !important;
      background: white;
      border-radius: 5px;
    }
  }

  .view-ticket .message-input {
    .mat-mdc-text-field-wrapper {
      background: #fff;
    }
    mat-hint {
      width: 100%;
      text-align: end;
      font-size: 10.5px;
    }
  }
}

body.theme-default .disabled-date-picker {
  .mat-mdc-input-element:disabled {
    color: inherit;
  }
}

.sub-menu.mat-mdc-menu-panel {
  min-width: 225px !important;
  min-height: auto;
  background-color: var(--primary-main) !important;
  overflow: hidden !important;
  border-radius: 0;

  .mat-mdc-menu-content {
    padding: unset !important;

    .mat-mdc-nav-list {
      padding: unset;

      :has(selected-item) {
        background-color: var(--primary-light) !important;
        border-top: 1px solid var(--primary-light) !important;
      }
    }
  }

  .nav-item:first-child {
    border-top: 1px solid var(--primary-main);
    :has(selected-item) {
      border-top: 1px solid var(--primary-light) !important;
    }
  }

  .nav-item {
    height: 46px;
    margin-right: unset;
    background-color: var(--primary-main);
    border-top: 1px solid var(--primary-contrastText);

    .add {
      border-bottom: 1px solid var(--primary-contrastText);

      .expanded-span {
        margin-top: unset !important;
      }
    }
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--primary-contrastText);
      height: inherit;
      text-decoration: none;
      height: inherit;
      span.sideNavIcons,
      mat-icon.sideNavIcons {
        margin-right: 0px;
        display: flex;
        svg path {
          fill: var(--primary-contrastText);
        }
      }

      &.active-link {
        text-decoration: none;
        height: 45.5px;
        ::ng-deep svg path {
          fill: var(--primary-main) !important;
        }
        .text {
          color: var(--primary-main) !important;
        }
      }

      span {
        margin: 0 auto;
      }
      add span.sideNavIcons svg {
        width: 23px;
        height: 23px;
        margin: 0 auto;
      }
      mat-icon.sideNavIcons {
        margin-left: 7px;
        font-size: 24px;
      }

      span.expanded-span {
        padding-left: 15px;
        margin-left: unset;
        padding-right: 12px;
      }

      .text {
        font-size: 12px;
        line-height: 21px;
      }
    }
    mat-divider.line {
      border: 0.4px solid var(--primary-contrastText);
    }
    &.selected-item {
      background-color: var(--primary-light) !important;
      border-top: 1px solid var(--primary-light) !important;
      border-right: 1px solid var(--primary-light);
      ::ng-deep .mat-expansion-indicator::after {
        color: var(--primary-main) !important;
      }
      .text {
        color: var(--primary-main) !important;
      }
      a span.sideNavIcons svg path {
        fill: var(--primary-main);
      }
    }
    &:hover {
      background-color: var(--primary-dark) !important;
      border-right: 1px solid var(--primary-dark);
      text-decoration: none;
      height: 45.5px;

      a {
        span.sideNavIcons,
        mat-icon.sideNavIcons {
          svg path {
            fill: var(--primary-contrastText) !important;
          }
        }
        .text {
          color: var(--primary-contrastText) !important;
        }
      }
    }
  }
}

.add-container-menu.mat-mdc-menu-panel {
  overflow: hidden;
  .mat-mdc-menu-content {
    background-color: var(--primary-main);
    padding: unset;
    overflow: hidden;

    .mat-mdc-nav-list {
      padding: unset;

      .nav-item {
        min-height: 46px;
        border-bottom: 1px solid var(--primary-contrastText);
        .ol-text-add {
          padding-left: 21px !important;
        }
      }
      button,
      button span {
        color: var(--primary-contrastText);
        font-size: 12px;
        line-height: 21px;
        font-weight: 700;

        span svg {
          display: block;
          path {
            fill: var(--primary-contrastText) !important;
          }
        }

        &:hover {
          background-color: var(--primary-dark) !important;
        }
      }

      mat-divider.line {
        border: 0.4px solid var(--primary-contrastText);
      }
    }
  }
}

.summary-card-menu.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    button .delete-filter {
      color: $uds-primary-blue;
      font-size: 12px;
      line-height: 21px;
      font-weight: 700;
    }
  }
}

.mat-mdc-menu-content {
  .link-share {
    margin: 0 16px;
    display: block;
    height: 40px;

    dp-sharing-link {
      line-height: unset;
      display: block;
      a {
        margin: 10px 0;
      }
    }
  }
}
