@mixin sidenav-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  #sidenav-list {
    mat-toolbar {
      @if ($is-dark) {
        background: map-get($background, card);
      }
    }
  }
}
