@mixin insights-reports-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);

  dp-insights-reports {
    width: 100%;
    .summary-card {
      background-color: map-get($background, background);
      color: map-get($foreground, divider);
    }
    .header {
      background-color: mat-color($accent, 500);
    }
  }
}
