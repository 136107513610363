@import 'scss/variables.scss';

// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin typography-theme($theme) {
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  a {
    color: map-get($accent, default);
  }

  code {
    &:not(.highlight) {
      background-color: map-get($background, app-bar);
    }
  }

  .changelog {
    .entry {
      background-color: map-get($background, card);
    }
  }

  .text-boxed {
    @if ($is-dark) {
      background-color: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.54);
    } @else {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
html {
  font-size: 62.5%;
  font-family: 'Montserrat', 'Arial', sans-serif;
  line-height: 1.4 !important;
  letter-spacing: -0.1px !important;
}

body {
  font-size: 14px;
}

html,
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
}

h1,
.h1 {
  font-size: 24px;
}

.dp-h1,
.uds-page-title {
  font-family: 'Conthrax';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

h2,
.h2 {
  font-size: 18px;
}

.dp-h2 {
  font-weight: 300;
  font-size: 60px;
  line-height: 90px;
  letter-spacing: -0.5px;
  color: #c4c4c4;
}

h3,
.h3 {
  font-size: 16px;
}

.dp-h3 {
  font-size: 48px;
  line-height: 72px;
  color: #c4c4c4;
}

h4,
.h4 {
  font-size: 15px;
}

.dp-h4 {
  font-size: 34px;
  line-height: 51px;
  letter-spacing: 0.25px;
  color: #c4c4c4;
}

//note: h1 <-> h4, not changed
// h*, body*, Subtitle*, Caption, ... link, all import
// from figma

h5,
.h5,
.dp-h5 {
  font-size: 24px;
  line-height: 36px;
  color: #c4c4c4;
}

h6,
.h6 {
  font-size: 12px;
}

.dp-h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.25px;
  color: #173a64;
}

.body-1,
.dp-body-1 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-feature-settings: 'ss07' on, 'liga' off;
  color: #c4c4c4;
}

.body-2,
.dp-body-2 {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #757575;
}

.subtitle-1,
.dp-subtitle-1 {
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #c4c4c4;
}

.subtitle-2,
.dp-subtitle-2 {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #c4c4c4;
}

.caption,
.dp-caption {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #c4c4c4;
}

.overline,
.dp-overline {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #c4c4c4;
}

.dp-link {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: #c4c4c4;
}

// end import from figma

// Links
a {
  text-decoration: none;

  // &:not(.mat-button):not(.mat-raised-button):not(.mat-mdc-unelevated-button):not(.mat-stroked-button):not(.mat-icon-button):not(.mat-fab):not(.mat-mdc-mini-fab) {

  //     &:hover, &:active {
  //         text-decoration: underline;
  //     }
  // }
}

// Abbr
abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
}

// Blockquote
blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px;

  footer {
    font-style: normal;

    &:before {
      content: '\2014 \00A0';
    }
  }

  &.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px;

    footer {
      &:before {
        content: '';
      }

      &:after {
        content: '\2014 \00A0';
      }
    }
  }
}

// Code
code {
  font-family: 'Monaco', 'Menlo', 'Consolas', 'Ubuntu Mono', monospace;
  &:not(.highlight) {
    color: #106cc8;
    padding: 2px 3px;
    border-radius: 2px;
    line-height: 1.7;
  }
}

// Definition lists
// dl {

//   dt {
//     font-weight: bold;
//   }

//   dd {
//     margin: 4px 0 16px 0;
//   }
// }

// Mark
mark {
  background: #f7f49a;
}

// Pre
pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap;
}

// Small
small {
  font-size: 80%;
}

// Table
table {
  thead {
    tr {
      th {
        text-align: left;
      }
    }
  }
}

// Text format helpers
.text-italic {
  font-style: italic;
}

.text-semibold {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

// Text align helpers
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// Font weight helpers
@for $weight from 1 through 9 {
  .font-weight-#{$weight * 100} {
    font-weight: #{$weight * 100};
  }
}

// Font size helpers
@for $size from 1 through 60 {
  .font-size-#{$size * 2} {
    font-size: #{$size * 2}px;
  }
}

// Line height helpers
@for $lineHeight from 1 through 60 {
  .line-height-#{$lineHeight * 2} {
    line-height: #{$lineHeight * 2}px;
  }
}

.line-height-1 {
  line-height: 1;
}

.line-height-1\.25 {
  line-height: 1.25;
}

.line-height-1\.5,
.line-height-1\.50 {
  line-height: 1.5;
}

.line-height-1\.75 {
  line-height: 1.75;
}

// Letter spacing helpers
.letter-spacing-condensed {
  letter-spacing: -0.02em;
}

.letter-spacing-normal {
  letter-spacing: normal;
}

.letter-spacing-relaxed {
  letter-spacing: 0.02em;
}

// Boxed text
.text-boxed {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
}

// Truncate
.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Nowrap
.text-nowrap {
  white-space: nowrap;
}

// Changelog
.changelog {
  .entry {
    margin-bottom: 24px;
    padding: 24px 32px;
    @include mat-elevation(2);

    > .title {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .version {
        font-size: 24px;
      }

      .date {
        margin-left: 8px;
        font-size: 17px;
        opacity: 0.54;
      }
    }

    .groups {
      div {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        display: inline-flex;
        font-size: 13px;
        color: white;
        letter-spacing: 0.015em;
        line-height: 1;
        padding: 5px 8px;
        border-radius: 2px;
      }

      .breaking-changes {
        .title {
          background: #f44336;
        }
      }

      .new {
        .title {
          background: #43a047;
        }
      }

      .improved {
        .title {
          background: #673ab7;
        }
      }

      .fixed {
        .title {
          background: #2196f3;
        }
      }

      ul {
        padding-left: 24px;

        li {
          margin-bottom: 6px;
          letter-spacing: 0.015em;

          ul {
            margin-top: 6px;
          }
        }
      }
    }
  }
}

// Message boxes
.message-box {
  padding: 16px;
  background: #607d8b;
  border-left: 6px solid #37474f;
  color: rgba(255, 255, 255, 1);

  &.error {
    background: #ef5350;
    border-left-color: #b71c1c;
  }

  &.warning {
    background: #ffecb3;
    border-left-color: #ffc107;
    color: rgba(0, 0, 0, 0.87);
  }

  &.success {
    background: #4caf50;
    border-left-color: #2e7d32;
  }

  &.info {
    background: #b3e5fc;
    border-left-color: #03a9f4;
    color: rgba(0, 0, 0, 0.87);
  }
}

.entity-label,
.entity-property {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.entity-label {
  display: inline-block;
  font-weight: 400;
  color: #757575;
  width: 120px;
}

.entity-property {
  font-weight: 700;
  color: #212121;
}

.entities {
  padding: 24px;

  div {
    margin-bottom: 12px;
  }
}

.fullHeight {
  height: 100%;
}

.minFullHeight {
  min-height: 100%;
}

.dpBusySpinner {
  left: 8px;

  circle {
    stroke: white !important;
  }
}

.mat-tiny-fab {
  width: 20px !important;
  height: 20px !important;
  line-height: 14px !important;
  font-size: 10px !important;
  box-shadow: none !important;

  .mdc-button__label {
    line-height: 14px !important;
    padding: 0 !important;
  }
}

.intro-right-wrapper {
  width: 384px;
  min-width: 384px;
  max-width: 384px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  @include mat-elevation(16);

  .intro-right {
    padding: 60px 40px 40px 40px;

    .title {
      font-size: 21px;
    }
  }

  .submit-button {
    width: 100%;
    margin: 40px auto;
    display: block;
  }

  .emailField {
    width: 250px;
  }
}
