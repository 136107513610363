@use '@angular/material' as mat;
@import './light-palette.scss';
@import 'partials/breakpoints';
@import './variables.scss';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core();
@include mat.legacy-core();

// change whole website $font-family
$dp-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.define-typography-level(15px, 24px, 300),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
);
$dp-legacy-typography: mat.define-legacy-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $display-4: mat.define-typography-level(112px, 112px, 300),
  $display-3: mat.define-typography-level(56px, 56px, 400),
  $display-2: mat.define-typography-level(45px, 48px, 400),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 32px, 400),
  $title: mat.define-typography-level(20px, 32px, 500),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(15px, 24px, 300),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  $input: mat.define-typography-level(16px, 1.25, 400),
);

@include mat.all-component-typographies($dp-typography);
@include mat.all-legacy-component-typographies($dp-legacy-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$light-primary: mat.define-palette($light-primary);
$light-accent: mat.define-palette($light-accent);
$light-warn: mat.define-palette($light-warning);
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
  )
);

$dark-primary: mat.define-palette(mat.$teal-palette);
$dark-accent: mat.define-palette(mat.$indigo-palette, 300);
$dark-warn: mat.define-palette(mat.$green-palette, 400);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
  )
);

@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);
@include mat.all-legacy-component-themes($light-theme);

.mat-expansion-panel,
.mat-drawer {
  color: white; // mat-color($light-accent, text);
  background: mat.get-color-from-palette($light-accent);
}

.mat-list-base .mat-mdc-list-item,
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: white;
}

.dark {
  @include mat.all-component-colors($dark-theme);
  @include mat.all-legacy-component-colors($dark-theme);

  .mat-expansion-panel,
  .mat-drawer {
    background: mat.get-color-from-palette($dark-accent);
  }
}

body,
html {
  margin: 0;
  height: 100%;
  font-size: mat.font-size($dp-typography, body-1);
  font-family: mat.font-family($dp-typography);
  line-height: mat.line-height($dp-typography, body-1);
}

.bg-white {
  background-color: white;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.no-visibility {
  visibility: hidden;
}

.block-full-height {
  display: block;
  height: 100%;
}

span.icons svg path {
  fill: white !important;
}

.mat-mdc-paginator-container {
  justify-content: space-between !important;
  padding: 0 !important;
}

mat-button-toggle-group {
  .mat-button-toggle-label-content {
    line-height: 30px !important;
  }
}

mat-button-toggle {
  min-width: 65px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 35px !important;
}

#containers-upload-page mat-paginator {
  background-color: transparent;

  .mat-mdc-paginator-range-actions {
    margin-right: -10px;
  }
}

// fix navbar too wide
.mat-toolbar-row,
.mat-toolbar-single-row {
  display: flex;
  box-sizing: border-box;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

// adjust tooltip
.mat-tooltip,
.mat-mdc-tooltip {
  font-size: 14px;
  padding: 8px;
  line-height: 21px;
}

countdown {
  span {
    color: #ffa000;
  }
}

// custom style for agm-marker label
agm-map div[style*='color: cyan'] {
  padding: 5px !important;
  color: black !important;
  background-color: white !important;
  opacity: 0.75;
  // border-radius: 50%;
}

mat-radio-group.filters mat-radio-button {
  &.high {
    .mdc-radio__inner-circle,
    .mdc-radio__outer-circle {
      border-color: $uds-alert-cherry !important;
    }
  }
  &.medium {
    .mdc-radio__inner-circle,
    .mdc-radio__outer-circle {
      border-color: #ffa000 !important;
    }
  }
  &.low {
    .mdc-radio__inner-circle,
    .mdc-radio__outer-circle {
      border-color: #00c853 !important;
    }
  }
}

.video-dlg-container {
  position: relative !important;

  .mat-dialog-container,
  .mat-mdc-dialog-surface {
    padding: 0;
  }
}

span.close-button,
button.close-button {
  color: #0080af !important;
}

// .cdk-overlay-dark-backdrop {
//     background: rgba(0, 0, 0, 0.6);
// }
.mat-dialog-container,
.mat-mdc-dialog-surface {
  position: relative;
  overflow: visible !important;
  padding: 40px;

  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    margin: 5px;
  }
}

.edit-product-dlg,
.share-partner-dlg {
  .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    padding: 24px;
  }
}
.share-partner-dlg .mdc-dialog .mdc-dialog__content {
  padding: 0 24px;
  margin: 0 -24px;
}

.dp-ocean-shipment-filters-dialog,
.dp-air-shipment-filters-dialog,
.dp-tbg-plus-filter-dialog,
.dp-road-filter-dlg,
.dp-tbg-plus-filter-dialog,
.dp-road-filter-dlg,
.dp-orders-advanced-table-column-dlg,
.dp-shipment-bing-maps-dialog,
.dp-board-bing-map-dialog,
.dp-ol-shipment-filters-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0;
  }
}

.dp-csv-dlg,
.dp-notification-metric-form-add,
.dp-bulk-share-with-partners-dialog,
.dp-purchase-order-dlg,
.dp-container-tracking-dialog,
.dp-add-partner-dialog,
.dp-shipment-product-dlg,
.new-ticket-dialog,
.lib-confirmation-dialogue,
.dp-edit-accounts-limits-dialog,
.dp-organization-column-dlg,
.dp-announcement-dlg,
.app-confirm-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 24px;

    .mdc-dialog__content {
      color: #000000;
    }
  }
}

.app-confirm-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    .mat-mdc-dialog-content p {
      margin-bottom: 20px;
    }
  }
}

.mat-mdc-dialog-container {
  &:has(new-ticket-dialog),
  &:has(lib-confirmation-dialogue) {
    .mdc-dialog__surface {
      padding: 24px;

      .mdc-dialog__content {
        color: #000000;
      }
    }
  }

  .mdc-dialog__surface .mat-mdc-dialog-content {
    display: block;
    margin: 0 -24px -24px;
    max-height: 65vh;
    overflow: auto;
    letter-spacing: -0.1px;
  }
}
.mdc-radio {
  padding: 0px 4px 0px 0px !important;

  .mdc-radio__native-control:enabled:checked + .mdc-radio__background {
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      border-color: $uds-primary-blue !important;
    }
  }
}
.mat-mdc-checkbox {
  .mdc-checkbox:not(:hover) .mdc-checkbox__ripple {
    background: transparent !important;
  }
  .mdc-checkbox {
    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
    }
    .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: #fff !important;
    }
  }
}
.mat-mdc-form-field-error-wrapper {
  padding: 0 8px !important;
}
.mat-mdc-button,
.mat-mdc-unelevated-button {
  .mdc-button__label > * {
    vertical-align: middle;
  }
  &.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
  &:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0 !important;
  }
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0 !important;
}
.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 1px solid #bebec1;
  }
  .mat-mdc-tab:hover .mdc-tab__ripple::before {
    opacity: 0 !important;
  }
}
.mat-mdc-select-panel {
  font-size: 14px;
  .mat-mdc-option {
    min-height: 3em;

    &.mdc-list-item.contains-mat-select-search {
      padding: 0;
    }

    &.mdc-list-item--disabled:has(ngx-mat-select-search) {
      opacity: 1 !important;

      .mat-select-search-no-entries-found {
        opacity: 0.38 !important;
      }
    }
  }
}
.mat-mdc-icon-button {
  &:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0 !important;
  }
  .mat-mdc-button-touch-target {
    width: inherit !important;
  }
}
.mat-mdc-table:not(.uds-table) {
  thead .mat-mdc-header-cell {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #0000008a !important;
  }

  tbody .mat-mdc-cell {
    font-weight: normal;

    &:not(.number-column) {
      line-height: 20px;
      color: #000000de !important;
    }
  }
}
.footer-snack {
  max-width: 100vw !important;
  width: 100vw !important;
  margin-bottom: 0px !important;
  padding: 7px 16px !important;
  min-height: auto !important;
}

button.medium {
  font-weight: 300;
}

.remindMe {
  float: right;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #007ad9 !important;
  }
}

.panelDisabled {
  background: rgba(255, 255, 255, 0.6);
}

.mde-popover-content {
  mat-card-title {
    padding-left: 12px;
    line-height: 20px;
  }

  .mat-mdc-list-item {
    height: 40px !important;
  }

  .mat-list-icon {
    padding: 4px 0 0 0 !important;
  }

  .mat-list-text {
    padding-left: 8px !important;
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-item {
    height: 48px;
  }
}

.addContainerMenu {
  .mat-mdc-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-mdc-menu-item {
    height: 40px;
    min-height: 40px;
    line-height: 40px;
  }
}

.cdk-overlay-pane:has(.result) {
  width: 282px;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel):has(.mat-mdc-select-panel.ellipsis) {
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cdk-overlay-pane:has(.mat-mdc-select-panel):not(:has(.mat-mdc-select-panel.no-change)):not(
    :has(.mat-mdc-select-panel[aria-labelledby^='mat-paginator-page-size-label'])
  ) {
  width: auto !important;
  max-width: 280px;

  mat-option .mdc-list-item__primary-text {
    white-space: nowrap;
  }
}
.mdc-menu-surface.mat-mdc-select-panel {
  padding-top: 0px !important;
}
.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0px !important;
  mat-option .mdc-list-item__primary-text {
    white-space: nowrap;
    font-size: 16px;
  }
}
.cdk-overlay-pane:has(.mat-mdc-select-panel.width-250) {
  width: 250px !important;
}

.result {
  max-width: 282px !important;
  max-height: 380px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  margin: 2px 0 0 -50px !important;

  .mat-mdc-menu-content {
    display: flex !important;
    flex-direction: column;
    width: 282px;
    padding: unset !important;
  }

  .empty {
    height: 100px !important;
    padding: 19px 20px 5px 20px !important;
    opacity: 1 !important;
    .mdc-list-item__primary-text {
      display: block !important;
    }
  }

  .mat-mdc-menu-item {
    height: unset;
    width: inherit;
    font-weight: 400;
    font-size: 15px;
    line-height: 25.2px;
    padding: 12px 21px 10px 21px;
    overflow: hidden;
    display: block;
    white-space: initial;
    word-break: break-word;
    background-color: unset !important;

    &:hover {
      background-color: #e3f2fd !important;
    }

    &:first-child {
      margin-top: 9px !important;
    }

    &:last-child {
      margin-bottom: 11px !important;
    }

    .search-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
    }

    .entity-type {
      font-weight: 700;
    }

    .main {
      font-family: Roboto;
      font-size: 18px;
      line-height: 25.2px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 11px;
    }
    .sub {
      font-size: 15px;
      line-height: 21px;
      font-weight: 400;
      color: #6a6a6d;
      text-align: center;
    }
  }
}

.div-centered {
  margin: 0 auto;
}

//<--ie11 only css
.svgOrange {
  fill: none;
  stroke: #ff9800;
  stroke-miterlimit: 10;
  stroke-width: 0.7px;
}

.svgFillNone {
  display: none;
  fill: none;
  stroke: #15ff92;
  stroke-width: 0.6;
}

.svgLineWhite {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.6;
}

//ie11 only css -->

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.inline-block {
  display: inline-block;
}

//calendar cell selected
.cal-day-selected,
.cal-day-selected:hover {
  background-color: #ebf4fc !important;
  //background-color: #ccc !important;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(250, 132, 51, 0.1);
}

.cal-month-view .cal-day-cell.cal-out-month.cal-has-events {
  cursor: pointer;
}

// .cal-month-view .cal-cell-row:hover {
//   background-color: #777777 !important;
// }

.cal-month-view .cal-header {
  font-weight: 600;
  background-color: white;
  color: #6a6a6d;

  &:hover {
    background-color: #777777;
  }

  .cal-cell {
    background-color: white;
    cursor: default;

    &:hover {
      background-color: #777777;
    }
  }
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: rgba(0, 0, 0, 0.87);
}

//note: this class can be used for all pages
.pageContentHeight {
  height: calc(100% - 70px); //70px for the height of the top page header
}

.mdc-tab__text-label {
  min-width: 40px !important;
  //padding: 0 4px !important; roll back this. too much impact for many places.
}

.gm-svpc {
  display: none; //remove pegman on the map
}

$tempImageSize: 26px;

.temperatureIcon {
  width: $tempImageSize;
  height: $tempImageSize;
  margin-left: 8px;
  vertical-align: middle;
}

.indicatorContent {
  @include Mat-Tooltip($uds-primary-ocean);
  overflow: initial;
  margin-top: 1rem;
  right: calc(50% - 0.5rem);
  border-radius: 3px;
  padding-left: 12px;
  padding-right: 12px;

  .title {
    font-size: 14px;
    color: #757575;
  }

  p {
    color: $uds-grayscale-white;
    margin: 8px 0;
    font-size: 16px;
  }

  dt {
    font-size: 14px;
    color: #757575;
    margin-bottom: 10px;
  }

  dd {
    margin-left: 0;
    font-size: 16px;
  }
}
.indicatorContent.indicatorContentWhite {
  @include Mat-Tooltip($uds-grayscale-white);
}
.indicatorContent.indicatorWidth {
  max-width: 130px;
}
.indicatorContent:after {
  top: -0.5rem;
  right: calc(50% - 0.5rem);
  transform: rotate(0);
}
.HIGH {
  color: #f57600 !important;
}

.LOW {
  color: #3c92ef !important;
}

.gm-style-iw {
  &.gm-style-iw-c {
    padding: 7px 20px 2px 7px !important;
  }
  .gm-style-iw-ch {
    display: none;
  }
  .gm-ui-hover-effect {
    position: absolute !important;
    right: 0;
    top: 0;
    width: 20px !important;
    height: 20px !important;
    span {
      width: 20px !important;
      height: 20px !important;
      margin: 0 !important;
    }
  }
  .gm-style-iw-d {
    overflow: hidden !important;
    padding: 0 6px 6px 0;
  }
}

.agm-info-window-content {
  // border: 1px solid red;
  height: 30px;
  line-height: 30px;
  text-align: center;

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  .svg {
    padding-left: 4px;
    padding-right: 6px;
    margin-right: 6px;
    border-right: 1.5px solid darkgray;
  }
}

.dpTable {
  // from ocean shipments table promote it to global level
  // alternate row color, white had border
  .mat-mdc-row:nth-child(2n) {
    background-color: #f5f5f5;
    border-bottom: 0.2px solid whitesmoke;
  }

  .mat-mdc-row:nth-child(2n + 1) {
    border-bottom: 0.2px solid whitesmoke;
  }

  .mat-mdc-row.highlighted {
    background-color: #ccc !important;
  }

  td.mat-mdc-cell {
    border-bottom-width: 0 !important;
  }
}

.propertyCaption {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.52);
  // min-width: 75px;
  width: 75px;
  display: inline-block;

  &:after {
    content: ':';
  }
}

dp-address,
.halfWidth {
  display: inline-block;
  width: 50%;
}

.mat-column-money {
  padding-right: 20px !important;
  text-align: right !important;
  justify-content: flex-end !important;
  width: 130px;
  max-width: 130px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
}

// this is our preferred way to center a div
.centerChild {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centerVertical {
  display: flex;
  align-items: center;
}

.centerBox {
  height: fit-content;
  width: fit-content;
  // background-color: white;
  color: white;
  padding: 20px;
  font-size: 1.5em;
  // opacity: 1;
}

.cal-month-view .daySelected {
  background-color: #bad8f3 !important;
}

.toBottom {
  margin-top: auto;
}

.toRight {
  margin-left: auto !important;
  display: block !important;
}

.hide {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

#dashboard-page {
  .mat-form-field-label {
    font-size: 1.2em;
  }
}

#notificationDlg {
  .mat-mdc-tab-body-wrapper,
  .mat-mdc-tab-body {
    height: 100%;
  }

  .mat-mdc-tab-header {
    margin: 0 20px 0 20px;
  }
}

.height100Full {
  min-height: 100%;
  height: 100%;
}

.panelUnderSelect {
  margin-top: 30px;
}
.uds-page-host {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.uds-page-content {
  margin-bottom: 20px;
  overflow-y: auto;
}
.uds-page-content-fill {
  height: calc(100% - 70px); //70px for the height of the top page header
  display: flex;
  flex-direction: column; //todo: maybe???
}

mat-sidenav-container,
.mat-drawer-container {
  background-color: #f1f1f1 !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.mat-toolbar-multiple-rows {
  min-height: 57px;
}
.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 57px;
}

span.uds-icon {
  width: 40px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  a.uds-icon-link {
    // padding: 5px 0;
    height: 100%;

    &:hover {
      cursor: pointer;
    }

    // in case dp-svg component not used
    .wrapper {
      // display: inline-flex;
      width: 25px;
      height: 25px;
      // justify-content: center;
      // align-items: center;
    }
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

mat-icon[matDatepickerToggleIcon] {
  transform: scale(0.9);
}

span[type='button'] {
  -webkit-appearance: none;
}

.loading > span.mat-option-text {
  display: flex;
  justify-content: center;
}

.global-search > span.mat-option-text {
  font-size: 15px;
  line-height: 21px;
  font-weight: 400;
}

.hidden {
  display: none !important;
}

// change all selected content to blue in dropdowns, hard code the color for now
.filter .mat-mdc-select-value {
  color: #2196f3 !important;
}

.filter .mat-date-range-input,
.filter .mat-date-range-input-separator {
  color: #2196f3 !important;
}

span.user-tag {
  color: #007ba8;
  font-weight: 700;
}
.cdk-overlay-container ngx-mat-select-search .mat-select-search-clear {
  top: 1px !important;
  right: -15px !important;
}

mat-form-field.bg-none {
  &:hover,
  .mdc-text-field--filled,
  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:hover {
    background-color: transparent !important;
  }
}

body .access-denied {
  display: none !important;
}

body .access-viewonly {
  opacity: 0.38 !important;
  &:active {
    pointer-events: none;
  }

  &.mat-mdc-header-cell {
    opacity: 1 !important;
  }
  .mat-ripple {
    display: none;
  }
}

tooltip.tooltip {
  background-color: $uds-primary-ocean;
  border-radius: 4px;
  padding: 10px;
  text-align: left;
  max-width: 250px;
  &::after {
    border-width: 7px !important;
  }
  &.tooltip-bottom::after {
    margin-left: -10px !important;
    border-bottom-color: $uds-primary-ocean !important;
  }
  &.tooltip-left::after {
    margin-top: -10px !important;
    border-left-color: $uds-primary-ocean !important;
  }
  &.tooltip-top::after {
    margin-right: -10px !important;
    border-top-color: $uds-primary-ocean !important;
  }
  &.tooltip-right::after {
    margin-top: -10px !important;
    border-right-color: $uds-primary-ocean !important;
  }
  &.themeTooltip {
    background-color: var(--primary-main);
    color: var(--primary-contrastText);
    &.tooltip-bottom::after {
      border-bottom-color: var(--primary-main) !important;
    }
    &.tooltip-left::after {
      border-left-color: var(--primary-main) !important;
    }
    &.tooltip-top::after {
      border-top-color: var(--primary-main) !important;
    }
    &.tooltip-right::after {
      border-right-color: var(--primary-main) !important;
    }
  }
  &.applied-filter-tooltip {
    max-width: 243px;
    width: 243px;
    padding: 22px 29px 14px;
  }
  &.create-summary-card {
    max-width: 346px;
    width: 346px;
    padding: 22px 29px 14px;
  }
  &.save-btn-tooltip {
    max-width: 236px;
    width: 236px;
    padding: 14px 11px 14px;
  }
  .wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.applied-filter-tooltip,
  &.create-summary-card,
  &.save-btn-tooltip {
    border-radius: 3px;
    &::after {
      border-width: 11px !important;
    }
    .title {
      display: flex;
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      border-bottom: 0.4px solid;
      padding-bottom: 5px;
      span {
        flex: 1;
      }
    }
    .sub-heading {
      margin-top: 5px;
      font-size: 10px;
      font-style: italic;
      font-weight: 300;
      line-height: 18px;
      text-align: left;
    }
    .filters {
      display: flex;
      margin-top: 2px;
      position: relative;
      span {
        font-size: 10px;
        flex: 1;
        text-align: left;
      }
      span:nth-child(odd) {
        font-style: italic;
        font-weight: 300;
      }
      span:nth-child(even) {
        font-weight: 500;
      }
      .more {
        position: absolute;
        right: -8px;
      }
    }
  }
  &.create-summary-card .sub-heading {
    margin-left: 10px;
    p {
      margin: 0;
    }
  }
  &.save-btn-tooltip {
    .title {
      padding-top: 4px;
    }
    .title,
    .sub-heading {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.mat-mdc-snack-bar-container {
  border-radius: 3px;
  .mat-mdc-snack-bar-label {
    font-weight: normal !important;
  }
  .container {
    padding: 0;
  }
  &.success .mdc-snackbar__surface {
    background: $uds-secondary-fescue !important;
  }
  &.error .mdc-snackbar__surface,
  &.failed .mdc-snackbar__surface {
    background: $uds-alert-cherry !important;
  }
  &.warn .mdc-snackbar__surface {
    background-color: #ff9800;
    .mat-mdc-snack-bar-label {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  &.accent .mdc-snackbar__surface {
    background-color: #039be5;
    .mat-mdc-snack-bar-label {
      color: #fff;
    }
  }
}

.mr-10 {
  margin-right: 10px !important;
}
.scrollY {
  overflow-y: scroll;
}
.capitalize-first-letter {
  display: inline-block; /* for span */
}
.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}
// overwrite mat-content class to remove flex: 1
.adjust-mat-content span.mat-content {
  display: block;
  flex: none;
}

.mat-mdc-form-field {
  .mat-mdc-form-field-icon-suffix {
    height: 36px;
    button {
      min-width: auto;
      mat-icon {
        font-size: 21px;
      }
    }
  }
  .mat-mdc-text-field-wrapper {
    flex: unset;
    .mat-mdc-form-field-flex {
      align-items: center;
    }
  }
  .mat-mdc-form-field-infix {
    min-height: unset;
  }
}

.mat-datepicker-toggle {
  height: inherit;
  display: inline-block;
  .mat-mdc-icon-button {
    height: inherit !important;
  }
}

dp-ocean-shipment-filters-dialog .mat-date-range-input-wrapper {
  overflow: unset;
}

new-ticket-dialog .mat-form-field-appearance-outline .mdc-text-field--outlined:has(textarea) {
  height: inherit !important;
}

mat-tooltip-component {
  display: none !important;
}

.cdk-overlay-pane:has(.demurrage-filter) {
  max-width: 400px !important;

  mat-option .mdc-list-item__primary-text {
    white-space: nowrap;
  }
}
.cdk-overlay-pane:has(.teamUserSelect) {
  max-width: 420px !important;
}

.demurrage-filter {
  width: 360px !important;
}
.value {
  .Pending {
    color: $pending;
  }
  .Accepted {
    color: $completed;
  }
  .Rejected {
    color: $rejected;
  }
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  border: 5px solid rgba(211, 211, 211, 0.3);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  vertical-align: middle;

  &.tracking {
    background-color: $uds-primary-ocean;
  }

  &.manual {
    background-color: $uds-tertiary-tiger;
  }
}

.square {
  height: 15px;
  width: 15px;
  display: inline-block;
  border: 3px solid rgba(211, 211, 211, 0.3);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  vertical-align: middle;

  &.tracking {
    background-color: black;
  }

  &.manual {
    background-color: $uds-tertiary-tiger;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: transparent !important;
}

.new-card-view-popover {
  .card-view-tooltip-body {
    border: 1px solid #007ba8 !important;
    width: 420px;
    padding: 5px !important;
    font-weight: 700;
    font-size: 12px;
    .title {
      font-size: 16px;
    }
    .new-chip {
      background: #076284;
      color: #fff;
      padding: 2px 8px;
      border-radius: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
      display: inline-block;
    }
    .desc {
      color: #6a6a6d;
      font-weight: 400;
      line-height: 18px;
    }
    .mdc-card__actions {
      justify-content: space-between;
      button {
        font-size: 12px;
        font-weight: 700;
        &.not-now-btn {
          color: #0b78d0;
        }
      }
    }
  }
  .mde-popover-direction-arrow::after {
    content: '';
    display: block;
    border: solid;
    border-width: 1px 15px 16px;
    border-style: solid;
    border-color: transparent transparent #fff;
    position: relative;
    right: 15px;
  }
}

.introjs-tooltipReferenceLayer * {
  font-family: 'Montserrat' !important;
}
.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 0px 0px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
.introjs-tooltip {
  font-size: 12px;
  font-weight: 400;
  padding: 15px;
  &.chatbot-tour-tooltip {
    width: 550px;
    max-width: 550px;
    .introjs-tooltip-header {
      display: none;
    }
    .introjs-skipbutton {
      display: none;
    }
  }
  .introjs-tooltip-header {
    padding: 0;
    margin-bottom: 5px;
    .introjs-tooltip-title {
      font-size: 12px;
    }
  }
  .introjs-tooltiptext {
    padding: 0;
    ol {
      padding-left: 25px;
    }
  }
  .introjs-arrow {
    border: 12px solid transparent;
    &.bottom-middle,
    &.bottom-right {
      bottom: -22px;
    }
  }
}
