@import './variables.scss';

@mixin fonts-theme($theme) {

  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $primary: map-get($theme, primary);

  h2, h3 {
    font-weight: 600;
  }
    
    // heading main Toggle Group
    .mat-mdc-tab-links {
      .mat-mdc-tab-link .mdc-tab__text-label {
        font-weight: 700;
        opacity: 0.6 !important;
        font-size: 12px;
        color: $uds-grayscale-shadow !important;
      }
    
      .mdc-tab--active .mdc-tab__text-label{
          font-size: 12px;
          color: $uds-primary-blue  !important;
          opacity: 1 !important;
        }
    }

    // tabs in detail page
    .mdc-tab__content {
       .mdc-tab__text-link {
         font-weight: 700;
         font-size: 12px;
         // opacity: 1 !important;
         color: $uds-grayscale-shadow  !important;
       }
         .mdc-tab--active .mdc-tab__text-label{
           font-size: 12px;
           color: $uds-primary-blue  !important;
           opacity: 1 !important;
        }
     }
     
    .mat-mdc-tab-list {
      flex-grow : 0;
    }

    // mat-tap-group.dashboard .mat-mdc-tab-labels {
    //   .mdc-tab--active{
    //       color: $uds-primary-blue  !important;
    //       opacity: 1 !important;
    //     }
    // }
 
  

    mat-button-toggle-group.toggle-groups {
      button {
          font-size: 12px;
          text-transform: uppercase;
          color: $uds-primary-blue;
          font-weight: 700;
      }
    }

    // detail section
    .detail {
      position: relative;

      .mat-mdc-tab-labels {
        display: flex;
      }

      .mat-tab-label,
      .mat-mdc-tab {
        min-width: 20px !important;
        padding: 0 20px !important;
        // color: $uds-grayscale-shadow;
        font-weight: 700;
      }

      .mat-tab-label:last-child,
      .mat-mdc-tab:last-child {
        margin-left: auto;
        color: unset;
        opacity: unset;
      }
      .mdc-tab__content {
        pointer-events: all;
      }
      .mat-mdc-tab-list {
        flex-grow: 1;
      }
    }

    span.label, label.label {
      color: $uds-grayscale-shadow;
    }
    span.value {
      font-weight: 600;
    }

    .miniFab {
      font-size: 12px;
      font-weight: 700;
      margin: 0 5px;
      padding: 5px;
      border-radius: 25px;
      min-width: 25px;
      height: 25px;
      color: black;
      opacity: 1;
      background-color: $uds-grayscale-fog;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &.active {
        color: white;
        background-color: $uds-primary-ocean;
      }
    }

    .text-heading {
      color: $uds-primary-ocean;
    }

    .h2-heading {
      margin: 10px 0px;
      color: $uds-primary-ocean;
      // @include Heading-Sub-Large
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;

      > label {
        margin-right: 13px;
      }
    }

    .fs-18 {
      font-size: 18px;
    }

    .text-bold-5 {
      font-weight: 500;
      // color: #000;
    }

    .text-bold, .text-bold-7 {
      font-weight: 700;
    }

  .text-bold-6 {
      font-weight: 600;
    }

    .text-light {
      font-weight: normal;
      color: rgba(0, 0, 0, 0.6);
    }

    // For Color Status
    .status {
      .statusDot {
        vertical-align: bottom;
      }

      span {
        &.ACTIVE, &.Active {
          color: $active;
        }

        &.COMPLETED, &.Completed {
          color: $completed;
        }

        &.NEW, &.Pending {
          color: $pending;
        }

        &.UNTRACKABLE, &.Untrackable {
          color: $untrackable;
        }

        &.FAILED,
        &.Failed {
          color: #AD2F33;
        }
      }
    }

    // For Color Status in Upload-history/Container-card
    .status-pill {
      em {
        font-style: normal;
      }

      span {
        color: white !important;
        padding: 5px 13px;
        border-radius: 13px;

        &.ACTIVE,
        &.Active {
          background-color: $active;
        }

        &.COMPLETED,
        &.Completed,
        &.FINISHED,
        &.Finished {
          background-color: $completed;
        }

        &.NEW,
        &.Pending {
          color: black !important;
          background-color: $pending;
        }

        &.UNTRACKABLE,
        &.Untrackable {
          background-color: $untrackable;
        }

        &.FAILED,
        &.Failed {
          background-color: #AD2F33;
        }
      }
    }

    // Old Design
    // Shipment status color in Table
    td div.status-label {
      width: 8px;
      padding: 18px 0;

      &.ACTIVE,
      &.IN_TRANSIT {
        background-color: $active;
      }

      &.COMPLETED,
      &.DELIVERED {
        background-color: $completed;
      }

      // New
      &.PENDING,
      &.NEW,
      &.ORDERED {
        background-color: $pending;
      }

      // Not Available
      &.CANCELED,
      &.UNTRACKABLE {
        background-color: $untrackable;
      }
    }
  
    .text-underline {
      text-decoration: underline !important;
    }

    .nowrap {
      white-space: nowrap;
    }

    a.link {
      color: $uds-primary-blue;
      cursor: pointer;
      white-space: nowrap;
    }

    a.link:hover {}

    .fs-9 {
      font-size: 9px !important;
    }

    .fs-10 {
      font-size: 10px !important;      
    }

    .fs-12 {
      font-size: 12px !important;
    }

    .fs-13 {
      font-size: 13px !important;
    }

    .fs-24 {
      font-size: 24px !important;

    }

  
    .text-primary-ocean {
      color: $uds-primary-ocean !important;
      font-weight: 700;
    }

    .text-shadow {
      color: $uds-grayscale-shadow;
      font-weight: 400 !important;
    }


  .productSelect {
    // background: rgb(219, 144, 5);
    height: 350px;
    overflow-y: scroll;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    min-width: 600px !important;
  }
}
