.mat-mdc-card-header-text {
  margin: 0 !important;
}

.subtitle1 {
  font-size: 16px;
  color: #212121;
  font-weight: normal;
  padding-right: 5px;
}

.subtitle2 {
  font-size: 13px;
  color: #999;
  font-weight: normal;
}

.subtitle2-up {
  color: #999;
  overflow: hidden;
  // display: inline-block;
}

.heightedItem {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// insight page
// ngx-chart custom css

.chart-legend .legend-labels {
  width: auto !important;
}

.text-muted {
  color: #9e9e9e;
}

.ngx-file-drop__drop-zone {
  // height: 250px !important;
  height: 100% !important;
  margin: auto;
  border-radius: 0 !important;
  padding: 5px 10px;
}

.ngx-file-drop__content {
  height: 100% !important;
}

button.mat-icon-button {
  //width: 30px; //note: don't change to 30px with this selector. A big impact to all other places such as date-picker

  mat-icon {
    // font-size: 20px;

    &:hover {
      // background-color: rgba(0, 0, 0, 0.1);
    }

    &:not(.disabled-text):hover {
      // color: #ffc107 !important;
    }
  }
}
button.mdc-icon-button {
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}
.mat-mdc-form-field-icon-suffix .mat-datepicker-toggle {
  width: 30px;
  right: 0 !important;
  svg.mat-datepicker-toggle-default-icon {
    width: 21px;
  }
}

// mat-icon {
//   &:hover {
//     background-color: rgba(0, 0, 0, 0.1);
//   }
// }

button.mat-mdc-menu-item {
  display: flex;
  align-items: center;
}

.mat-mdc-menu-item .mat-icon {
  color: #666;
  margin-right: 8px !important;
  // vertical-align: middle;
}

body.theme-default .mat-button-toggle-checked {
  background-color: #E3F2FD !important;
  // color: black !important;
}

mat-tab-group.tbg {
  height: calc(100% - 40px);

  .mat-mdc-tab-body-wrapper{
    height: 100%;
  }
}

.custom-header-sort .mat-sort-header-container {
  justify-content: flex-end;
}

.backdropBackground {
  filter: blur(10px);
  background-color: #999;
  opacity: 0.7 !important;
}

// apply to specific class dp
div[role='listbox'].dp {
  height: 100%;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
}

.clear-all-section {
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 40px;
  border-top: 0.3px solid #ccc;
}

.mat-mdc-menu-panel {
  max-width: 100% !important;
}